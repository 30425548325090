import "moment/min/locales";
import moment from "moment";
import AppUtils from "../utils/AppUtils";

export const actions = {
    AUTH_USER: 'AUTH_USER',
    UNAUTH_USER: 'UNAUTH_USER',
    SET_TENANT: 'SET_TENANT',
    SET_UI_ROUTES: 'SET_UI_ROUTES',
    SET_APPLICATION_LOCALE: "SET_APPLICATION_LOCALE",
    SET_APPLICATION_LANG: "SET_APPLICATION_LANG"
};

export interface UserReducerState {
    user: any;
    tenant: any;
    locales: any;
    locale: any;
    lang: string;
    uiRoutes: String[];
}

const initialState: UserReducerState = {
    user: null,
    tenant: null,
    locales: moment.locales(),
    locale: getApplicationLocale(),
    lang: getApplicationLang(),
    uiRoutes: [],
};

const userReducer = (state: UserReducerState = initialState, action: any) => {

    state = {...state};

    switch (action.type) {
        case actions.AUTH_USER:
            state.user = action.payload;
            return state;
        case actions.UNAUTH_USER:
            state.user = null;
            return state;
        case actions.SET_TENANT:
            state.tenant = action.payload;
            return state;
        case actions.SET_UI_ROUTES:
            state.uiRoutes = action.payload;
            return state;
        case actions.SET_APPLICATION_LOCALE:
            state.locale = action.payload;
            window.localStorage.setItem(AppUtils.LOCAL_STORAGE_ITEM_LOCALE, JSON.stringify(action.payload));
            return state;
        case actions.SET_APPLICATION_LANG:
            state.lang = action.payload;
            setLangToLocalStorage(action.payload);
            return state;
        default:
            return state
    }
};

export default userReducer

function getApplicationLocale(): { label: string, value: string } {
    try {
        const locale: { label: string, value: string } = JSON.parse(window.localStorage.getItem(AppUtils.LOCAL_STORAGE_ITEM_LOCALE) as string);
        if (!locale) {
            return setLocaleToLocalStorage();
        }
        return locale;
    } catch (e) {
        return setLocaleToLocalStorage();
    }

    function setLocaleToLocalStorage(): { label: string, value: string } {
        const localeTag = moment.locale();
        const localeOption = {
            label: AppUtils.getLocaleNameByTag(localeTag),
            value: localeTag
        };
        window.localStorage.setItem(AppUtils.LOCAL_STORAGE_ITEM_LOCALE, JSON.stringify(localeOption));
        return localeOption;
    }
}

function getApplicationLang(): string {
    let lang = window.localStorage[AppUtils.LOCAL_STORAGE_ITEM_LANG];
    if (!lang) {
        lang = navigator.language ? navigator.language.split('-')[0] : AppUtils.APP_DEFAULT_LANGUAGE;
        setLangToLocalStorage(lang);
    }
    return lang;
}

function setLangToLocalStorage(lang: string): string {
    window.localStorage.setItem(AppUtils.LOCAL_STORAGE_ITEM_LANG, lang);
    return lang;
}
