import {graphQlRequest, mySelf, updateUserLocale} from "../config/axios/ApiRequests";
import history from "../config/history";
import AppUtils from "./AppUtils";
import moment from "moment";
import store from "../store";
import {actions} from "../reducers/userReducer";
import {PublicRoutes, Routes, UserRoles} from "./appEnums/AppEnums";
import * as gql from "gql-query-builder";
import GraphQlHelper from "./GraphQlHelper";
import {actions as personActions} from "../reducers/personReducer";
import Cookies from 'js-cookie'

export interface UserPreference {
    locale: {value: string, label: string};
}

export default class AuthUtils {
    private static readonly PREFERENCE_MISSING_VALUE = "n/a";
    private static readonly SESSION_STORAGE_ACCESS_TOKEN = "access_token";
    private static readonly SESSION_STORAGE_REFRESH_TOKEN = "refresh_token";
    static user: any = null;

    static getAccessToken = () => {
        // return sessionStorage.getItem(AuthUtils.SESSION_STORAGE_ACCESS_TOKEN);
        return Cookies.get(AuthUtils.SESSION_STORAGE_ACCESS_TOKEN);

    };

    static isRefreshTokenPresent = (): boolean => {
        // return !!sessionStorage.getItem(AuthUtils.SESSION_STORAGE_REFRESH_TOKEN);
        return !!Cookies.get(AuthUtils.SESSION_STORAGE_REFRESH_TOKEN);
    };

    static isAccessTokenPresent = (): boolean => {
        // return !!sessionStorage.getItem(AuthUtils.SESSION_STORAGE_ACCESS_TOKEN);
        return !!Cookies.get(AuthUtils.SESSION_STORAGE_ACCESS_TOKEN);
    };

    static getRefreshToken = () => {
        // return sessionStorage.getItem(AuthUtils.SESSION_STORAGE_REFRESH_TOKEN);
        return Cookies.get(AuthUtils.SESSION_STORAGE_REFRESH_TOKEN);
    };

    static setTokenCookies = (accessToken: string, refreshToken: string, accessTokenExpiry: number, refreshTokenExpiry: number) => {
        // sessionStorage.setItem(AuthUtils.SESSION_STORAGE_ACCESS_TOKEN, accessToken);
        // sessionStorage.setItem(AuthUtils.SESSION_STORAGE_REFRESH_TOKEN, refreshToken);
        Cookies.set(AuthUtils.SESSION_STORAGE_ACCESS_TOKEN, accessToken, {expires: moment().add(accessTokenExpiry, 'second').toDate()})
        Cookies.set(AuthUtils.SESSION_STORAGE_REFRESH_TOKEN, refreshToken, {expires: moment().add(refreshTokenExpiry, 'second').toDate()})
    };

    static clearTokenCookies = () => {
        // sessionStorage.removeItem(AuthUtils.SESSION_STORAGE_ACCESS_TOKEN);
        // sessionStorage.removeItem(AuthUtils.SESSION_STORAGE_REFRESH_TOKEN);
        Cookies.remove(AuthUtils.SESSION_STORAGE_ACCESS_TOKEN)
        Cookies.remove(AuthUtils.SESSION_STORAGE_REFRESH_TOKEN)
    };

    static isUserManager = () => {
        const index = AuthUtils.user?.roles.findIndex(item => item.toLowerCase() === UserRoles.Manager.toLowerCase())
        return index !== -1;
    }

    static isUserEmployee = () => {
        const index = AuthUtils.user?.roles.findIndex(item => item.toLowerCase() === UserRoles.Employee.toLowerCase())
        return index !== -1;
    }

    static isUserClient = () => {
        const index = AuthUtils.user?.roles.findIndex(item => item.toLowerCase() === UserRoles.Client.toLowerCase())
        return index !== -1;
    }

    static hasUserEmployeeRole = (): boolean => {
        return AuthUtils.isUserEmployee() && !AuthUtils.user?.mta && !AuthUtils.user?.systemAdministrator
    }

    static objectToEncodedRequestBody = (object: any): string => {
        let formBody: string[] = [];
        for (let property in object) {
            if (object.hasOwnProperty(property)) {
                let encodedKey = encodeURIComponent(property);
                let encodedValue = encodeURIComponent(object[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
        }
        return formBody.join("&");
    };

    static updateUserPreferences = (myselfResponse: any, currentUserPreference: UserPreference) => {
        AuthUtils.updateUserLocale(myselfResponse.locale, currentUserPreference);
    };

    static updateUserLocale = (myselfLocale: any, currentUserPreference: UserPreference) => {
        const preferredLocale = AuthUtils.retrievePreferredLocale(myselfLocale);
        if (preferredLocale && preferredLocale !== currentUserPreference.locale.value) {
            store.dispatch({
                type: actions.SET_APPLICATION_LOCALE,
                payload: {
                    value: preferredLocale,
                    label: AppUtils.getLocaleNameByTag(preferredLocale)
                }
            })
        }
        const saveUserPreferredLocale = preferredLocale !== myselfLocale;
        if (saveUserPreferredLocale) {
            updateUserLocale(preferredLocale)
                .catch(() => {
                    AppUtils.error("Failed saving user preferred location.")
                });
        }
    };

    static retrievePreferredLocale = (myselfLocale: any) => {
        if (myselfLocale && myselfLocale !== AuthUtils.PREFERENCE_MISSING_VALUE) {
            return myselfLocale;
        }
        const locale: { label: string, value: string } = JSON.parse(window.localStorage.getItem(AppUtils.LOCAL_STORAGE_ITEM_LOCALE) as string);
        return locale ? locale.value : moment.locale();
    };

    static mySelfRequest = (userPreference: UserPreference) => {
        mySelf()
            .then(response => {
                AuthUtils.updateUserPreferences(response.data, userPreference);
                AuthUtils.user = response.data;

                const loadPersonQuery = gql.query({
                    operation: `Person(qry: "userId==${response.data.id}", page: {offsetRecords: 0, limitRecords: 10})`,
                    fields: GraphQlHelper.clientFields
                })
                graphQlRequest(loadPersonQuery, false)
                    .then(personResponse => {
                        if (personResponse.data.data?.Person) {
                            const rec = personResponse.data.data['Person'].records.length > 0 ? personResponse.data.data['Person'].records[0] : null;
                            store.dispatch({
                                type: personActions.SET_PERSON,
                                payload: rec
                            });
                        } else if (personResponse.data.errors && personResponse.data.errors.length > 0) {
                            AppUtils.error(personResponse.data.errors.map(item => item.message).join('\n'));
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        AppUtils.error('Something went wrong while loading person data');
                    })

                const routes = Object.keys(Routes);
                if (response.data.mta || response.data.systemAdministrator || AuthUtils.isUserManager() || AuthUtils.isUserEmployee()) {
                    let uiRoutes = routes.map(route => Routes[route]);
                    store.dispatch({type: actions.SET_UI_ROUTES, payload: Array.from(uiRoutes)});
                    store.dispatch({type: actions.AUTH_USER, payload: response.data});
                } else {
                    // Client case
                    let uiRoutes: string[] = routes.filter(route => Routes[route] !== Routes.SHOP).map(route => Routes[route]);
                    store.dispatch({type: actions.SET_UI_ROUTES, payload: Array.from(uiRoutes)});
                    store.dispatch({type: actions.AUTH_USER, payload: response.data});
                }
            })
            .catch(() => {
                store.dispatch({type: 'set', showSpinner: false })
                AuthUtils.clearTokenCookies();
                history.push(`${PublicRoutes.LOGIN}`);
            });
    };
}
