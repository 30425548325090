import axios, {AxiosResponse} from "axios";
import AuthUtils from "../../utils/AuthUtils";
import Axios from "./Axios";
import AxiosActions from "./AxiosActions";

export const logInKeyCloak = async (username: string, password: string): Promise<AxiosResponse> => {
    let details = {
        'client_id': `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
        'username': username,
        'password': password,
        'grant_type': "password"
    };

    return AxiosActions.post(Axios.instances.V1,`${process.env.REACT_APP_KEYCLOAK_URL}/auth/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/token`,
        AuthUtils.objectToEncodedRequestBody(details), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }, false);
};

export const refreshAccessToken = async(): Promise<AxiosResponse> => {
    let details = {
        'client_id': `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
        'grant_type': "refresh_token",
        'refresh_token': AuthUtils.getRefreshToken()
    };

    return axios.post(`${process.env.REACT_APP_KEYCLOAK_URL}/auth/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/token`,
        AuthUtils.objectToEncodedRequestBody(details), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
}

export const mySelf = async (): Promise<AxiosResponse> => {
    return AxiosActions.get(Axios.instances.V2, `user/myself`, {}, false)
};

export const updateUserLocale = async (localeTag: string): Promise<AxiosResponse> => {
    return AxiosActions.post(Axios.instances.V2,`user/locale?locale=${localeTag}`, {}, {}, false);
};

export const getUserFunctionalities = async (): Promise<AxiosResponse> => {
    return AxiosActions.get(Axios.instances.V2,`functionality/user/all?allowed=true&limit=999999`, {}, false)
};

export const getFile = async (id: number) => {
    return AxiosActions.get(Axios.instances.V1,`/boi/${id}/file/download/preview`, {responseType: 'blob',timeout: 15000}, false, true)
};

export const getUserMetadata = async (): Promise<AxiosResponse[]> => {
    return AxiosActions.all([
        AxiosActions.get(Axios.instances.V2, "tenant", {} , false),
        AxiosActions.get(Axios.instances.V2, "user/roles", {}, false)
    ], true)
};

export const graphQlRequest = async (query: any, spinner: boolean): Promise<AxiosResponse> => {
    return AxiosActions.post(Axios.instances?.graphqlInstance,`graphql`, query, {}, spinner, false)
};

export const uploadFile = async (file: any): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('file', file);
    return AxiosActions.post(Axios.instances.V1,`boi/file/upload?instanceName=${file.name}`, formData, {
        headers: {
            "Accept": "application/json",
        },}, true, true)
};

export const uploadFiles = async (requests: any) => {
    const [...responses] = await Promise.all(requests);
    return responses;
};

export const inviteUser = async (dto: any): Promise<AxiosResponse> => {
    return AxiosActions.post(Axios.instances.V2, `user/invite`, dto, {}, true);
};

export const getAllRoles = async (): Promise<AxiosResponse> => {
    return AxiosActions.get(Axios.instances.V2, `access-control/role/all?limit=9999`, {}, false);
};

export const assignRoleToUser = async (userId: number, roleId: number): Promise<AxiosResponse> => {
    return AxiosActions.post(Axios.instances.V2, `access-control/user/${userId}/role/${roleId}`, {}, {}, true);
};

export const removeRoleFromUser = async (userId: number, roleId: number): Promise<AxiosResponse> => {
    return AxiosActions.delete(Axios.instances.V2, `access-control/user/${userId}/role/${roleId}`,  {}, true);
};

export const uploadBuildFiles = async (files: any) => {
    const [...responses] = await Promise.all(files.map(file => {
        const formData = new FormData();
        formData.append('file', file);
        return AxiosActions.post(Axios.instances.V1, `boi/file/upload?instanceName=${file.name}`, formData, {
            headers: {
                "Accept": "application/json",
            },
        }, true, true);
    }));
    return responses;
};

export const getType = async (key: string, showSpinner: boolean): Promise<AxiosResponse> => {
    return AxiosActions.get(Axios.instances.V2, `type/key/${key}`, {}, showSpinner);
};

export const downloadFileById = async (id: number): Promise<AxiosResponse> => {
    return AxiosActions.requestWithProgress(Axios.instances.V1, 'get', `boi/${id}/file/download`, {}, {responseType: "blob"}, true)
    // return AxiosActions.get(Axios.instances.V1, `boi/${id}/file/download`, {responseType: "blob"}, true);
};

export const downloadFilesByIds = async (ids: number[]): Promise<AxiosResponse> => {
    return AxiosActions.requestWithProgress(Axios.instances.V1, 'get', `boi/file/zip?ids=${ids.join("&ids=")}`, {}, {responseType: "blob"}, true)
    // return AxiosActions.get(Axios.instances.V1, `boi/file/zip?ids=${ids.join("&ids=")}`, {responseType: "blob"}, true);
};

export const forgotPassword = async (dto: any): Promise<AxiosResponse> => {
    return AxiosActions.post(Axios.instances.V2, `/public/reset-password`, dto, {withCredentials: false}, true);
};

export const updateUser = async (userId: number, dto): Promise<AxiosResponse> => {
    return AxiosActions.post(Axios.instances.V2, `user/${userId}/edit`, dto, {}, true);
};

export const sendAllRequests = async (requests: []): Promise<AxiosResponse[]> => {
    return AxiosActions.all(requests, true)
};

export const sendSms = async (dto: any): Promise<AxiosResponse> => {
    return AxiosActions.post(Axios.instances.V2, `twilio/send`, dto, {}, false);
};

export const sendEmail = async (dto: any): Promise<AxiosResponse> => {
    return AxiosActions.post(Axios.instances.V2, `email/send`, dto, {}, false);
};

export const getUser = async (id: number): Promise<AxiosResponse> => {
    return AxiosActions.get(Axios.instances.V2, `user/${id}`, {}, false);
};

export const getTenantConfig = async (): Promise<AxiosResponse> => {
    return AxiosActions.get(Axios.instances.V2, `/public/tenant/config`,  {withCredentials: false}, true);
};

export const getTypeInstances = async (typeId: number, params: any): Promise<AxiosResponse> => {
    return AxiosActions.post(Axios.instances.V1, `boi/data/${typeId}?dataFormat=MAP&dataView=full&limit=10&page=0`,
        params, {}, true);
};

export const getInstanceRelationships = async (instanceId: number): Promise<AxiosResponse> => {
    return AxiosActions.get(Axios.instances.V1, `/boi/${instanceId}/relationships`,  {}, true);
};

export const createFileVersion = async (fileId: number, isCurrent: boolean, file: any, versionName?: string): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('fileNewVersion', file);
    return AxiosActions.post(Axios.instances.V1,
        `boi/file/${fileId}/version?current=${isCurrent}${versionName ? `versionName=${versionName}` : ''}`,
        formData, {}, true);
};
