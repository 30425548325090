
export enum Routes {
    BASE_PATH = '/',
    PROJECT = '/project/id=:id',
    SHOP = '/shop'
}

export enum PublicRoutes {
    LOGIN = '/login',
    FORGOT_PASSWORD = '/forgot-password'
}

export enum StaticRoutes {
    FORBIDDEN = '/forbidden',
    NOT_FOUND = '/not-found',
}

export enum Files {
    JPG = 'image/jpeg,image/x-citrix-jpeg,image/pjpeg,',
    PNG = 'image/png,image/x-citrix-png,image/x-png,',
    BMP = 'image/bmp,',
    ICO = 'image/x-icon',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,',
    PDF = 'application/pdf'
}

export enum UserRoles {
    Client = 'Shop Client',
    Employee = 'Shop Employee',
    Manager = 'Shop Manager',
}
