import * as React from 'react';
import {Fade, Modal, Typography} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import * as gql from "gql-query-builder";
import EditIcon from '@material-ui/icons/Edit';
import {useDispatch} from "react-redux";
import AppUtils from "../../utils/AppUtils";
import {graphQlRequest, updateUser} from "../../config/axios/ApiRequests";
import GraphQlHelper from "../../utils/GraphQlHelper";
import {actions as personActions} from "../../reducers/personReducer";
import {CustomTextField} from "../../layouts/MainLayout";
import './editProfile.css';
import {CustomCheckbox} from "../../views/MerchantPage/components/BuildCard/BuildCard";

export interface IProfile {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    userId: number;
    email: string;
    phoneNumber: string;
    receivesEmails: string;
    receivesSms: string;
}

interface Props {
    open: boolean;
    clientForEdit: IProfile;

    handleClose(): void;
}

const EditProfile = (props: Props) => {
    const customTheme = useTheme();
    const useStyles = makeStyles((theme: Theme) => createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `2px solid ${customTheme.palette.primary.main}`,
            inset: '10px !important',
            '@media: (max-width: 400px)': {
                maxWidth: 350
            }
        },
        paper: {
            width: 600,
            backgroundColor: customTheme.palette.background.default,
            border: `2px solid ${customTheme.palette.primary.main}`,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 2, 2),
        },
        closeButton: {
            // backgroundColor: customTheme.palette.secondary.main,
            // color: 'white',
            // border: `1px solid ${customTheme.palette.secondary.main}`,
            maxWidth: 180
        },
        confirmButton: {
            backgroundColor: customTheme.palette.secondary.main,
            color: customTheme.palette.secondary.contrastText,
            border: `1px solid ${customTheme.palette.secondary.main}`,
        },
        formControl: {
            width: '100%',
            border: `1px solid ${customTheme.palette.primary.main}`,
            overflow: 'hidden',
            borderRadius: 4,
            backgroundColor: customTheme.palette.background.paper
        }
    }));
    const classes = useStyles();

    const [firstName, setFirstName] = useState<string>('');
    const [firstNameError, setFirstNameError] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [lastNameError, setLastNameError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');
    const [receivesEmails, setReceivesEmails] = useState<boolean>(false);
    const [receivesSms, setReceivesSms] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.clientForEdit) {
            fillForm();
        }
    }, [props.clientForEdit]);

    const fillForm = () => {
        setFirstName(props.clientForEdit?.firstName ?? '');
        setLastName(props.clientForEdit?.lastName ?? '');
        setEmail(props.clientForEdit?.email ?? '');
        setPhone(props.clientForEdit?.phoneNumber ?? '');
        setReceivesEmails(props.clientForEdit?.receivesEmails === 'true');
        setReceivesSms(props.clientForEdit?.receivesSms === 'true');
    }

    const onFirstNameChange = evt => {
        if (evt.target.value.split('').length <= 20) {
            setFirstName(evt.target.value);
        }
    };

    const onLastNameChange = evt => {
        if (evt.target.value.split('').length <= 20) {
            setLastName(evt.target.value);
        }
    };

    const onEmailChange = evt => {
        if (evt.target.value.split('').length <= 50) {
            setEmail(evt.target.value);
        }
    };

    const onPhoneChange = evt => {
        const validCharacters = evt.target.value.replace(/[^0-9+-.()]/g, '');
        if (validCharacters.length < 20) {
            setPhone(validCharacters);
        }
    };

    const handleEmailChange = () => {
        setReceivesEmails(!receivesEmails);
    }

    const handleSMSChange = () => {
        setReceivesSms(!receivesSms);
    }

    const setDefaultState = () => {
        setFirstName('');
        setFirstNameError('');
        setLastName('');
        setLastNameError('');
        setEmail('');
        setEmailError('');
        setPhone('');
        setPhoneError('');
    }

    const closeModal = () => {
        setDefaultState();
        props.handleClose();
    }

    const isFormValid = () => {
        let isFormValid = true;

        if (firstName === '') {
            setFirstNameError("First Name cannot be empty.");
            isFormValid = false;
        } else if (firstNameError !== '') {
            setFirstNameError('');
        }

        if (lastName === '') {
            setLastNameError("Last Name cannot be empty.");
            isFormValid = false;
        } else if (lastNameError !== '') {
            setLastNameError('');
        }

        if (email === '') {
            setEmailError("Email cannot be empty.");
            isFormValid = false;
        } else if (!AppUtils.VALID_EMAIL_REGEX.test(email)) {
            setEmailError("Email is not valid.");
            isFormValid = false;
        } else if (emailError !== '') {
            setEmailError('');
        }

        if (phone === '') {
            setPhoneError("Phone Number cannot be empty.");
            isFormValid = false;
        } else if (phone.split('').filter(ch => ch === '+').length > 1) {
            setPhoneError("Phone Number cannot contain more than one '+' character.");
            isFormValid = false;
        } else if (phone.replace(/[^0-9]/g,"").length !== 10) {
            setPhoneError("Phone Number must contain exactly 10 digits.");
            isFormValid = false;
        } else if (phoneError !== '') {
            setPhoneError('');
        }

        return isFormValid
    };

    const checkForChanges = (): boolean => {
        const keys = Object.keys(props.clientForEdit ?? {});
        let areThereChanges = false;
        keys.forEach(key => {
            switch (key) {
                case 'firstName':
                    if (firstName !== props.clientForEdit?.firstName) {
                        areThereChanges = true;
                    }
                    break;
                case 'lastName':
                    if (lastName !== props.clientForEdit?.lastName) {
                        areThereChanges = true;
                    }
                    break;
                case 'email':
                    if (email !== props.clientForEdit?.email) {
                        areThereChanges = true;
                    }
                    break;
                case 'phoneNumber':
                    if (phone !== props.clientForEdit?.phoneNumber) {
                        areThereChanges = true;
                    }
                    break;
                case 'receivesEmails':
                    if (receivesEmails !== (props.clientForEdit?.receivesEmails === "true")) {
                        areThereChanges = true;
                    }
                    break;
                case 'receivesSms':
                    if (receivesSms !== (props.clientForEdit?.receivesSms === "true")) {
                        areThereChanges = true;
                    }
                    break;
            }
        })
        return areThereChanges
    }

    const editClientHandler = () => {
        if (isFormValid()) {
            if (!checkForChanges()) {
                AppUtils.warning('There are no changes');
                return;
            }
            const updatePersonMutation = gql.mutation({
                operation: `UpdatePersonRecords(qry: "id==${props.clientForEdit?.id}",
                                    name: "${firstName} ${lastName}",
                                    firstName: "${firstName}",
                                    lastName: "${lastName}",
                                    phoneNumber: "${phone}",
                                    receivesEmails: "${receivesEmails}",
                                    receivesSms: "${receivesSms}",
                                    email: "${[email]}", maxRecordsToUpdate: 1 )`,
                fields: GraphQlHelper.clientFields
            })
            graphQlRequest(updatePersonMutation, true)
                .then(gqlResponse => {
                    if (gqlResponse.data.data?.UpdatePersonRecords) {
                        dispatch({
                            type: personActions.SET_PERSON,
                            payload: gqlResponse.data.data?.UpdatePersonRecords.records[0]
                        });
                        updateUser(props.clientForEdit.userId, {
                            firstName,
                            lastName,
                            email: Array.isArray(email) ? email[0] : email
                        }).then(() => {
                            AppUtils.success(`Profile has been updated successfully`);
                            closeModal();
                        }).catch(error => {
                            AppUtils.error(error.response?.data?.message)
                        })
                    } else if (gqlResponse.data.errors && gqlResponse.data.errors.length > 0) {
                        AppUtils.error(gqlResponse.data.errors.map(item => item.message).join('\n'));
                    }
                })
                .catch(gqlError => {
                    console.error(gqlError);
                    AppUtils.error('Something went wrong while updating user');
                })
        }
    }


    return (<Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={() => closeModal()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open}>
                <div className={classes.paper}>
                    <header>
                        <Typography variant="h4" className={'modal-header'}>
                            User Profile
                        </Typography>
                    </header>
                    <CustomTextField
                        key={"first-name"}
                        label="First Name"
                        variant="filled"
                        value={firstName}
                        onChange={onFirstNameChange}
                        helperText={firstNameError}
                        error={firstNameError !== ''}
                        autoFocus={true}
                    />
                    <CustomTextField
                        key={"last-name"}
                        label="Last Name"
                        variant="filled"
                        value={lastName}
                        onChange={onLastNameChange}
                        helperText={lastNameError}
                        error={lastNameError !== ''}
                    />
                    <CustomTextField
                        key={"email"}
                        label="Email"
                        variant="filled"
                        value={email}
                        onChange={onEmailChange}
                        helperText={emailError}
                        error={emailError !== ''}
                    />
                    <CustomTextField
                        key={"phone"}
                        label="10 Digit Phone Number"
                        variant="filled"
                        value={phone}
                        onChange={onPhoneChange}
                        helperText={phoneError}
                        error={phoneError !== ''}
                        autoComplete="off"
                    />
                    <div className={'notification-container'}>
                        <div className={'notification-checkbox'}>
                            <Typography gutterBottom variant="subtitle2"
                                        style={{color: 'white', lineHeight: 4, marginBottom: 1}}>
                                I want to receive Emails
                            </Typography>
                            <CustomCheckbox gutterBottom checked={receivesEmails}
                                            onChange={handleEmailChange} name="checked"/>
                        </div>
                        <div className={'notification-checkbox'}>
                            <Typography gutterBottom variant="subtitle2"
                                        style={{color: 'white', lineHeight: 4, marginBottom: 1}}>
                                I want to receive Text messages
                            </Typography>
                            <CustomCheckbox gutterBottom checked={receivesSms}
                                            onChange={handleSMSChange} name="checked"/>
                        </div>
                    </div>
                    <footer className={'modal-footer'}>
                        <Button color="inherit"
                                className={'close-button'}
                                startIcon={<CloseIcon/>}
                                onClick={() => closeModal()}
                                variant="outlined">
                            Close
                        </Button>
                        <Button color="secondary"
                                className={classes.confirmButton}
                                startIcon={<EditIcon/>}
                                onClick={editClientHandler}
                                variant="contained">Submit</Button>
                    </footer>
                </div>
            </Fade>
        </Modal>
    )
}
export default EditProfile
