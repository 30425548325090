import * as React from 'react';
import {connect} from "react-redux";
import App from "../App";
import Axios from "axios";
import IntlUtils from "../utils/IntlUtils";
import {BaseReducerState} from "../reducers/baseReducer";
// @ts-ignore
import {IntlProvider, InjectedIntlProps} from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

interface IntlLayoutProps {
    lang: string,
    intl?: InjectedIntlProps
}

interface IntlLayoutState {
    pending: boolean;
    messages: any;
}

class IntlAppLayout extends React.Component<IntlLayoutProps, IntlLayoutState> {
    constructor(props: IntlLayoutProps) {
        super(props);
        this.state = {
            pending: true,
            messages: {}
        }
    };

    componentDidMount() {
        Axios.get("/lang/")
            .then((response: any) => {
                try {
                    if (IntlUtils.$.parseHTML(response.data).length === 0) {
                        const langFiles = response.data.filter((item: string) => item.includes('.json'));
                        this.getLanguages(langFiles);
                    } else {
                        const elements = IntlUtils.$(response.data);
                        const found = IntlUtils.$("a:contains(.json)", elements);
                        const langFiles = IntlUtils.$.map(found, (item: any, index: number) => IntlUtils.$(item)[0].innerText);
                        this.getLanguages(langFiles);
                    }
                } catch (e) {
                    console.warn(e);
                }
            });
    };

    getLanguages = (langFiles: string[]): void => {
        const promise: any[] = langFiles.map((file: any) => Axios.get(`/lang/${file}`));
        Axios.all(promise)
            .then((responses) => {
                const files: any = {};
                responses.forEach((item: any, index: number) => {
                    files[langFiles[index].split(/[.]/)[0]] = item.data
                });
                this.setState({
                    messages: files,
                    pending: false
                });
            })
            .catch((e) => {
                console.warn(e);
            });
    }

    render() {
        if (this.state.pending) {
            return null;
        }
        const locale = this.props.lang;
        return (
            <IntlProvider
                key={locale}
                locale={locale}
                messages={this.state.messages[locale]}
            >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <App/>
                </MuiPickersUtilsProvider>
            </IntlProvider>
        );
    }
}

const mapStateToProps = (reduxState: BaseReducerState) => {
    return {
        lang: reduxState.userReducer.lang
    };
}

export default connect(mapStateToProps, {})(IntlAppLayout);
