import * as React from 'react'
import MainLayout from "./layouts/MainLayout";
import {Route, Switch} from "react-router-dom";
import {Routes, StaticRoutes} from "./utils/appEnums/AppEnums";
import {AuthRoute} from "./components/AuthRoute/AuthRoute";
import NotFound from "./views/staticPages/NotFound/NotFound";

const HomePage = React.lazy(() => import('./views/HomePage/HomePage'));
const MerchantPage = React.lazy(() => import('./views/MerchantPage/MerchantPage'));
const Project = React.lazy(() => import('./views/BuildPage/Buildpage'));
const Forbidden = React.lazy(() => import('./views/staticPages/Forbidden/Forbidden'));

const privateRoutes = () => {
    return <MainLayout>
        <Switch>
            <AuthRoute
                exact={true}
                Component={HomePage} path={`${Routes.BASE_PATH}`}
                uiRoute={Routes.BASE_PATH}
            />
            <AuthRoute
                Component={MerchantPage} path={`${Routes.SHOP}`}
                uiRoute={Routes.SHOP}
            />
            <AuthRoute
                Component={Project} path={`${Routes.PROJECT}`}
                uiRoute={Routes.PROJECT}
            />
            <AuthRoute
                Component={Forbidden} path={`${StaticRoutes.FORBIDDEN}`}
                uiRoute={StaticRoutes.FORBIDDEN}
            />
            <Route component={NotFound}/>
        </Switch>
    </MainLayout>
}
export default privateRoutes;