import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import AuthUtils from "../../utils/AuthUtils";
import {refreshAccessToken} from "./ApiRequests";
import store from "../../store";
import {AxiosInstances} from "../../App";
import {PublicRoutes} from "../../utils/appEnums/AppEnums";
import AppUtils from "../../utils/AppUtils";

interface Instance {
    name: string,
    config: AxiosRequestConfig
}

export default class Axios {
    static API_BASE_URL: string = process.env.NODE_ENV === 'development' ? "http://localhost:8181/api" : `${process.env["REACT_APP_API_BASE_URL"]}`
    static cancelToken = axios.CancelToken.source();
    static cancelTokenModals = axios.CancelToken.source();
    static cancelTokenTimeOut: any = 0;
    static cancelTokenTimeOutModals: any = 0;
    static instances: AxiosInstances;

    // refresh token related
    private static isAlreadyFetchingAccessToken = false;
    private static subscribers = [];

    /**
     * Summary:
     * This method creates axios instances.
     * Note: create your instances in the App file
     */
    static createInstances(...instances: Instance[]) {
        const axiosInstances: any = {}
        instances.forEach(instance => {
            axiosInstances[instance.name] = axios.create(instance.config)
            this.interceptorsConfig(axiosInstances[instance.name]);
        })
        Axios.instances = {...axiosInstances};
    }

    // custom interceptor config
    private static interceptorsConfig(instance: AxiosInstance): void {
        instance.interceptors.request.use(
            config => {
                return this.appendAuthorizationBearerToken(config)
            },
            error => Promise.reject(error));

        instance.interceptors.response.use(
            response => response,
            async error => {
                const {config, response: {status}} = error;
                const originalRequest = config;
                if (status === 401 && !AuthUtils.isRefreshTokenPresent() && !AuthUtils.isAccessTokenPresent()){
                    if (!AppUtils.isPathPublic(window.location.pathname)) {
                        window.location.href = `${PublicRoutes.LOGIN}`;
                    } else {
                        return Promise.reject(error);
                    }
                } else if (status === 401 && AuthUtils.isRefreshTokenPresent()) {
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true;
                        refreshAccessToken()
                            .then((response) => {
                                AuthUtils.setTokenCookies(response.data.access_token, response.data.refresh_token, response.data.expires_in, response.data.refresh_expires_in);
                                this.isAlreadyFetchingAccessToken = false;
                                this.onAccessTokenFetched(response.data.access_token);
                            }).catch(() => {
                                AuthUtils.clearTokenCookies();
                                store.dispatch({type: 'set', showSpinner: false})
                            window.location.href = `${PublicRoutes.LOGIN}`;
                        });
                    }

                    return new Promise((resolve) => {
                        this.addSubscriber((access_token: any) => {
                            originalRequest.headers.Authorization = 'Bearer ' + access_token;
                            resolve(axios(originalRequest))
                        })
                    })
                } else if (error.request.responseType === 'blob' && error.response.data instanceof Blob &&
                    error.response.data.type && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
                    return new Promise((resolve, reject) => {
                        let reader = new FileReader();
                        reader.onload = () => {
                            if (typeof reader.result === "string") {
                                error.response.data = JSON.parse(reader.result);
                            }
                            resolve(Promise.reject(error));
                        };

                        reader.onerror = () => {
                            reject(error);
                        };

                        reader.readAsText(error.response.data);
                    });
                } else {
                    return Promise.reject(error);
                }
            })
    }

    static appendAuthorizationBearerToken = (config: any) => {
        const withCredentials = config.withCredentials ?? true;
        if (!withCredentials) {
            return config;
        }
        config.headers.Authorization = `Bearer ${AuthUtils.getAccessToken()}`;
        return config;
    };

    static addSubscriber = (callback: any) => {
        // @ts-ignore
        Axios.subscribers.push(callback)
    };

    static onAccessTokenFetched = (access_token: string) => {
        // @ts-ignore
        Axios.subscribers = Axios.subscribers.filter(callback => callback(access_token))
    };

    private static spinnerHandler = (showSpinner: boolean): void => {
        store.dispatch({type: 'set', showSpinner: showSpinner});
    }

    static async all<T>(values: (T | Promise<T>)[]): Promise<T[]> {
        return await axios.all(values);
    }
}

