import * as React from 'react'
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {AxiosInstance} from "axios";
import Axios from "./config/axios/Axios";
import AppUtils from "./utils/AppUtils";
import {ToastProvider} from "react-toast-notifications";
import ActionSpinner from "./config/axios/ActionSpinner/ActionSpinner";
import CircularProgressSpinner from "./config/axios/CircularProgressbar/CircularProgressSpinner";
import privateRoutes from "./routes";
import {PublicRoutes} from "./utils/appEnums/AppEnums";
import Toast from "./components/Toast/Toast";
import {createMuiTheme, ThemeProvider} from '@material-ui/core';
import {useEffect, useState} from "react";
import {getTenantConfig} from "./config/axios/ApiRequests";
import {useDispatch} from "react-redux";


const example = (
    <div></div>
)

export const DefinedAxiosInstances = {
    V1: 'V1',
    V2: 'V2',
    graphqlInstance: 'graphqlInstance'
}

export interface AxiosInstances {
    V1: AxiosInstance,
    V2: AxiosInstance,
    graphqlInstance: AxiosInstance,
}

Axios.createInstances(
    {
        name: DefinedAxiosInstances.V1,
        config: {
            baseURL: `${Axios.API_BASE_URL}${AppUtils.API_BASE_URL_V1_POSTFIX}`,
            cancelToken: Axios.cancelToken.token
        }
    },
    {
        name: DefinedAxiosInstances.V2,
        config: {
            baseURL: `${Axios.API_BASE_URL}${AppUtils.API_BASE_URL_V2_POSTFIX}`,
            cancelToken: Axios.cancelToken.token
        }
    },
    {
        name: DefinedAxiosInstances.graphqlInstance,
        config: {
            baseURL: `${Axios.API_BASE_URL}`,
            withCredentials: true,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Time-Zone': AppUtils.getCookie("Time-Zone")
            },
            cancelToken: Axios.cancelToken.token
        }
    }
)

const Login = React.lazy(() => import('./views/publicPages/Login/Login'));
const ForgotPass = React.lazy(() => import('./views/publicPages/ForgotPassword/ForgotPass'));

const defaultThemeConfig: any = {
    palette: {
        type: 'dark',
        primary: {
            main: '#27C499',
            contrastText: '#fff'
        },
        secondary: {
            main: '#27C499',
            contrastText: '#fff'
        },
        text: {
            primary: '#27C499',
            secondary: '#27C499'
        },
        background: {
            paper: '#232228',
            default: '#232228'
        },
        action: {
            disabled: 'rgba(255,255,255,0.9)',
            disabledBackground: '#177b61',
            active: '#27C499'
        }
    },
    typography: {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        allVariants: {
            color: '#fff',
        }
    },
    overrides: {
        MuiCheckbox: {
            root: {
                color: '#27C499',
            },
        },
    },
    props: {
        MuiFilledInput: {
            style: {
                border: `1px solid #27C499`,
                overflow: 'hidden',
                borderRadius: 4,
                color: `#fff`,
            },
            disableUnderline: true
        },
    }
}

const appThemeConfig: any = {
    palette: {
        type: 'dark',
        primary: {
            main: '#FF5200',
            contrastText: '#fff'
        },
        secondary: {
            main: '#FF5200',
            contrastText: '#fff'
        },
        text: {
            primary: '#FF5200',
            secondary: '#FF5200'
        },
        background: {
            paper: '#000000',
            default: '#000000'
        },
        action: {
            disabled: 'rgba(255,255,255,0.9)',
            disabledBackground: 'rgb(129, 89, 70)',
            active: '#FF5200'
        }
    },
    typography: {
        // fontFamily: '"Brush Script MT", "cursive"',
        allVariants: {
            color: '#fff',
        }
    },
    overrides: {
        MuiCheckbox: {
           root: {
               color: '#FF5200',
           },
        },
    },
    props: {
        MuiFilledInput: {
            style: {
                border: `1px solid #FF5200`,
                overflow: 'hidden',
                borderRadius: 4,
                color: `#fff`,
                // transition: theme.transitions.create(['border-color', 'box-shadow']),
                // '&:hover': {
                //     backgroundColor: '#2d2727',
                // },
                // '&$focused': {
                //     backgroundColor: '#2d2727',
                //     // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
                //     borderColor: "white",
                // },
            },
            disableUnderline: true
        },
    }
}

const App = () => {
    const dispatch = useDispatch();
    const [customTheme, setCustomTheme] = useState<any>(null)

    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    useEffect(() => {
        getTenantConfig()
            .then(response => {
                createTheme(response.data.themeConfiguration.muiTheme);
                if (response.data.publicUrlTitleIcon !== '' && response.data.publicUrlTitleIcon !== 'n/a'){
                    link.href = response.data.publicUrlTitleIcon;
                }
                window.parent.document.title = response.data.tenantName;
                dispatch({
                    type: 'themeProps',
                    themeProps: {
                        data: {
                            mainBackgroundImage: response.data.publicUrlBackground !== 'n/a' ?
                                response.data.publicUrlBackground : '',
                            loginBackgroundImage: response.data.publicUrlLoginImage !== 'n/a' ?
                                response.data.publicUrlLoginImage : '',
                            logoImage: response.data.publicUrlLogo !== 'n/a' ? response.data.publicUrlLogo : '',
                            titleIcon: response.data.publicUrlTitleIcon !== 'n/a' ? response.data.publicUrlTitleIcon : ''
                        },
                        appBackgroundStyle: {
                            backgroundImage: response.data.publicUrlBackground !== 'n/a' ?
                                `url(${response.data.publicUrlBackground})` : '',
                            backgroundColor: response.data.themeConfiguration.muiTheme.palette.type !== 'n/a' ?
                                response.data.themeConfiguration.muiTheme.palette.type : "black"
                        },
                        publicBackgroundStyle: {
                            backgroundImage: response.data.publicUrlLoginImage !== 'n/a' ?
                                `url(${response.data.publicUrlLoginImage})` : '',
                            backgroundColor: response.data.themeConfiguration.muiTheme.palette.type !== 'n/a' ?
                                response.data.themeConfiguration.muiTheme.palette.type : "black"
                        },
                        publicUrlLogo: response.data.publicUrlLogo !== 'n/a' ? response.data.publicUrlLogo : '',
                        messageBackground: response.data.themeConfiguration.customSettings.messageBackground
                    }
                });
            })
    }, [])

    const createTheme = (themeConfig: any) => {
        const themeKeysPalette = Object.keys(themeConfig.palette);
        themeKeysPalette.forEach(key => {
            switch (key) {
                case 'primary':
                    if (!isColorValid(themeConfig.palette[key].main)) {
                        themeConfig.palette[key].main = defaultThemeConfig.palette[key].main;
                    }
                    if (!isColorValid(themeConfig.palette[key].contrastText)) {
                        themeConfig.palette[key].contrastText = defaultThemeConfig.palette[key].contrastText;
                    }
                    break;
                case 'secondary':
                    if (!isColorValid(themeConfig.palette[key].main)) {
                        themeConfig.palette[key].main = defaultThemeConfig.palette[key].main;
                    }
                    if (!isColorValid(themeConfig.palette[key].contrastText)) {
                        themeConfig.palette[key].contrastText = defaultThemeConfig.palette[key].contrastText;
                    }
                    break;
                case 'text':
                    if (!isColorValid(themeConfig.palette[key].primary)) {
                        themeConfig.palette[key].primary = defaultThemeConfig.palette[key].primary;
                    }
                    if (!isColorValid(themeConfig.palette[key].secondary)) {
                        themeConfig.palette[key].secondary = defaultThemeConfig.palette[key].secondary;
                    }
                    break;
                case 'background':
                    if (!isColorValid(themeConfig.palette[key].paper)) {
                        themeConfig.palette[key].paper = defaultThemeConfig.palette[key].paper;
                    }
                    if (!isColorValid(themeConfig.palette[key].default)) {
                        themeConfig.palette[key].default = defaultThemeConfig.palette[key].default;
                    }
                    break;
                case 'action':
                    if (!isColorValid(themeConfig.palette[key].disabled)) {
                        themeConfig.palette[key].disabled = defaultThemeConfig.palette[key].disabled;
                    }
                    if (!isColorValid(themeConfig.palette[key].disabledBackground)) {
                        themeConfig.palette[key].disabledBackground = defaultThemeConfig.palette[key].disabledBackground;
                    }
                    if (!isColorValid(themeConfig.palette[key].active)) {
                        themeConfig.palette[key].active = defaultThemeConfig.palette[key].active;
                    }
                    break;
            }
        })

        const themeKeysTypography = Object.keys(themeConfig.typography);
        themeKeysTypography.forEach(key => {
            switch (key) {
                case 'allVariants':
                    if (!isColorValid(themeConfig.typography[key].color)) {
                        themeConfig.typography[key].color = defaultThemeConfig.typography[key].color;
                    }
                    break;
                case 'fontFamily':
                    if (themeConfig.typography[key] === '' || themeConfig.typography[key] === 'n/a') {
                        themeConfig.typography[key] = defaultThemeConfig.typography[key];
                    }
            }
        })

        themeConfig = {
            ...themeConfig,
            overrides: {
                MuiCheckbox: {
                    root: {
                        color: themeConfig.palette.secondary.main,
                    },
                },
            },
            props: {
                MuiFilledInput: {
                    style: {
                        border: `1px solid ${themeConfig.palette.primary.main}`,
                        overflow: 'hidden',
                        borderRadius: 4,
                        color: `#fff`,
                    },
                    disableUnderline: true
                },
            }
        }

        const theme = createMuiTheme(themeConfig);
        setCustomTheme(theme);
    }

    const isColorValid = (strColor): boolean => {
        const s = new Option().style;
        s.color = strColor;
        return s.color !== '';
    }

    if (customTheme === null) {
        return null;
    }

    return (
        <ThemeProvider theme={customTheme}>
            <ToastProvider>
                <React.Suspense fallback={example}>
                    <ActionSpinner/>
                    <CircularProgressSpinner/>
                    <BrowserRouter>
                        <Toast>
                            <Switch>
                                <Route path={`${PublicRoutes.LOGIN}`} component={Login}/>
                                <Route path={`${PublicRoutes.FORGOT_PASSWORD}`} component={ForgotPass}/>
                            </Switch>
                            {privateRoutes()}
                        </Toast>
                    </BrowserRouter>
                </React.Suspense>
            </ToastProvider>
        </ThemeProvider>
    )
}

export default App
