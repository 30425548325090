import * as React from 'react';
import {Redirect, Route, RouteComponentProps} from 'react-router-dom';
import store from "../../store";
import {useSelector} from "react-redux";
import {StaticRoutes} from "../../utils/appEnums/AppEnums";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

interface Props {
    Component: React.FC<RouteComponentProps> | any;
    path: string;
    exact?: boolean;
    uiRoute: string;
}


export const AuthRoute = ({Component, path, uiRoute, exact = false}: Props): JSX.Element => {
    const uiRoutes = useSelector((store: any) => store.userReducer.uiRoutes);

    const hasAccess = (): boolean => {
        if (uiRoute === StaticRoutes.FORBIDDEN ) {
            return true;
        }

        if (uiRoutes.length === 0) {
            return false;
        }

        const pathFound = uiRoutes.find(route => route === uiRoute);
        return !!pathFound;
    };


    const renderComponent = (routeProps: RouteComponentProps) => {
        const user = store.getState().userReducer.user;
        const userHasRequiredPath = user ? hasAccess() : false;

        if (user && userHasRequiredPath) {
            return <Component {...routeProps}/>
        }

        return <Redirect to={{
            pathname: `${StaticRoutes.FORBIDDEN}`,
            state: {form: path}
        }}/>

    }
    return (
        <ErrorBoundary>
            <Route
                path={path}
                exact={exact}
                render={(props: RouteComponentProps) => renderComponent(props)}
            />
        </ErrorBoundary>
    )
};
