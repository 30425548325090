import React from "react";

interface IThemeProps {
    data: any;
    appBackgroundStyle: React.CSSProperties;
    publicUrlLogo: string;
    publicBackgroundStyle: React.CSSProperties;
    messageBackground: string;
}
export interface UiReducerState {
    showShopButton: boolean,
    showBuildsButton: boolean,
    isProfileModalOpen: boolean;
    uploadedImagesForPreview: any;
    themeProps: IThemeProps;
}

const initialState: UiReducerState = {
    showShopButton: false,
    showBuildsButton: false,
    isProfileModalOpen: false,
    uploadedImagesForPreview: [],
    themeProps: {
        data: {},
        appBackgroundStyle: {
            backgroundImage: '',
            backgroundColor: "black",
        },
        publicUrlLogo: '',
        publicBackgroundStyle: {
            backgroundImage: '',
            backgroundColor: "black",
        },
        messageBackground: ''
    }
}

type args = { type?: string, [key: string]: any }

const UiReducer = (state: UiReducerState = initialState, { type, ...rest }: args) => {
    switch (type) {
        case 'showShopButton':
            return {...state, ...rest }
        case 'showBuildsButton':
            return {...state, ...rest }
        case 'isProfileModalOpen':
            return {...state, ...rest }
        case 'uploadedImagesForPreview':
            return {...state, ...rest }
        case 'themeProps':
            return {...state, ...rest }
        default:
            return state
    }
}
export default UiReducer
