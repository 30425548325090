import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import AuthUtils from "../../utils/AuthUtils";
import {PublicRoutes, Routes} from "../../utils/appEnums/AppEnums";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import {Menu, MenuItem, useMediaQuery} from "@material-ui/core";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import './appHeader.css'

const AppHeader = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const matches = useMediaQuery('(max-width: 768px)');
    const history = useHistory();
    const showShopButton = useSelector((store: any) => store.uiReducer.showShopButton);
    const showBuildsButton = useSelector((store: any) => store.uiReducer.showBuildsButton);
    const user = useSelector((store: any) => store.userReducer.user);
    const themeProps = useSelector((store: any) => store.uiReducer.themeProps);
    const theme = useTheme();
    const useStyles = makeStyles((theme: Theme) => createStyles({
        headerMenuItem: {
            borderBottom: `1px solid ${theme.palette.primary.main}`
        }
    }));
    const classes = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        AuthUtils.clearTokenCookies();
        setAnchorEl(null);
        window.location.href = `${PublicRoutes.LOGIN}`;
    }

    const navigateToShop = () => {
        history.push(Routes.SHOP);
        setAnchorEl(null);
    };

    const navigateToBuilds = () => {
        history.push(Routes.BASE_PATH);
        setAnchorEl(null);
    };

    const openEditProfileModal = () => {
        dispatch({type: 'isProfileModalOpen', isProfileModalOpen: true});
        handleClose();
    };

    const logoClickHandler = () => {
        if (AuthUtils.user.mta || AuthUtils.user.systemAdministrator || AuthUtils.isUserManager() || AuthUtils.isUserEmployee()) {
            history.push(`${Routes.SHOP}`);
        } else if (AuthUtils.isUserClient()) {
            history.push(`${Routes.BASE_PATH}`);
        }
    }

    const renderMenuItems = () => {
        if (matches) {
            return <React.Fragment>
                <IconButton edge="start" aria-controls="simple-menu" color="inherit" aria-label="menu"
                            aria-haspopup="true" onClick={handleClick}>
                    <MenuIcon/>
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            marginTop: "61px",
                            backgroundColor: 'rgb(0 0 0 / 85%)',
                            borderRadius: 'unset',
                            color: '#fff',
                            padding: '0 10px',
                            border: `1px solid ${theme.palette.primary.main}`
                        }
                    }}
                >
                    {showShopButton ? <MenuItem className={classes.headerMenuItem} onClick={navigateToShop}>Go To Shop</MenuItem> : null}
                    {showBuildsButton ? <MenuItem className={classes.headerMenuItem} onClick={navigateToBuilds}>Go To Builds</MenuItem> : null}
                    {user && AuthUtils.isUserClient() ?
                        <MenuItem className={classes.headerMenuItem}  onClick={openEditProfileModal}>Profile</MenuItem> : null
                    }
                    <MenuItem onClick={logOut}>Logout</MenuItem>
                </Menu>
            </React.Fragment>
        } else {
            return <div>
                {showShopButton ?
                    <Button color="secondary" variant="contained" className={'navigation-btn'}
                            onClick={() => {
                                history.push('/shop')
                            }}>
                        Go To Shop
                    </Button> : null}
                {showBuildsButton ?
                    <Button color="secondary" variant="contained" className={'navigation-btn'}
                            onClick={() => {
                                history.push('/')
                            }}>
                        Go To Builds
                    </Button> : null}
                {user && AuthUtils.isUserClient() ?
                    <React.Fragment>
                        <IconButton edge="start" aria-controls="simple-menu" color="inherit" aria-label="menu"
                                    aria-haspopup="true" onClick={handleClick}>
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    marginTop: "61px",
                                    backgroundColor: 'rgb(0 0 0 / 85%)',
                                    borderRadius: 'unset',
                                    color: '#fff',
                                    padding: '0 10px',
                                    border: `1px solid ${theme.palette.primary.main}`
                                }
                            }}
                        >
                            <MenuItem className={classes.headerMenuItem}  onClick={openEditProfileModal}>Profile</MenuItem>
                            <MenuItem onClick={logOut}>Logout</MenuItem>
                        </Menu>
                    </React.Fragment> :
                    <Button color="secondary"
                            variant="outlined"
                            className={'logout-btn'}
                            onClick={logOut}>
                        Logout
                    </Button>
                }
            </div>
        }
    }

    return (
        <AppBar position="fixed" className={'heritage-appBar'}>
            <Toolbar className={'heritage-header'}>
                <div className={'logo-container'}>
                    {themeProps?.publicUrlLogo !== '' && themeProps?.publicUrlLogo !== 'n/a' ?
                        <img src={themeProps?.publicUrlLogo}
                             alt="logo" className={'logo-img'} onClick={logoClickHandler}/> :
                        null
                    }
                </div>
                {renderMenuItems()}
            </Toolbar>
        </AppBar>
    )
}
export default AppHeader
