import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actions as authActions} from "../reducers/userReducer";
import {useHistory, useLocation} from "react-router";
import {getUserMetadata} from "../config/axios/ApiRequests";
import AuthUtils from "../utils/AuthUtils";
import AppUtils from "../utils/AppUtils";
import {PublicRoutes, Routes, StaticRoutes} from "../utils/appEnums/AppEnums";

const Wrapper = (props: any) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store: any) => store.userReducer.user);
    const locale = useSelector((store: any) => store.userReducer.locale);
    const [showChildren, setShowChildren] = useState<boolean>(false);

    useEffect(() => {
        if (AppUtils.isPathPublic(location.pathname) && !AuthUtils.isAccessTokenPresent()) {
            // access public page = log out
            AuthUtils.clearTokenCookies();
        } else if (AuthUtils.isAccessTokenPresent()) {
            AuthUtils.mySelfRequest({locale: locale});
        } else {
            history.push(`${PublicRoutes.LOGIN}`);
        }
    }, []);

    useEffect(() => {
        if (user) {
            getUserMetadata()
                .then(responses => {
                    dispatch({type: authActions.SET_TENANT, payload: responses[0].data});
                    setShowChildren(true);
                    if (history.location.pathname === `${PublicRoutes.LOGIN}`) {
                        if (AuthUtils.user.mta || AuthUtils.user.systemAdministrator || AuthUtils.isUserManager() || AuthUtils.isUserEmployee()) {
                            history.push(`${Routes.SHOP}`);
                        } else if ( AuthUtils.isUserClient()) {
                            history.push(`${Routes.BASE_PATH}`);
                        } else {
                            history.push(`${StaticRoutes.FORBIDDEN}`);
                        }
                    }
                })
                .catch(() => {
                    if (history.location.pathname !== `${PublicRoutes.LOGIN}`) {
                        history.push(`${PublicRoutes.LOGIN}`);
                    }
                    setShowChildren(false);
                });
        } else {
            setShowChildren(false);
        }
    }, [user]);

    if (showChildren) {
        return props.children
    }
    return null;
}

export default Wrapper
