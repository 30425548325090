
export default class GraphQlHelper {
    static allBuildFields = [
        {
            records: [
                'id',
                'userId',
                'name',
                'description',
                'status',
                'eta',
                'customerBuildSheetUrl',
                'shopBuildSheetUrl',
                'creatorId',
                {
                    BuildHasImageFile: [
                        'relationsCount',
                        {
                            relations: [
                                {
                                    record: [
                                        'id',
                                        'name',
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    BuildHasConversationImageFile: [
                        'relationsCount',
                        {
                            relations: [
                                {
                                    record: [
                                        'id',
                                        'name',
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    BuildIsOwnedByPerson: [
                        {
                            relations: [
                                {
                                    record: [
                                        'phoneNumber'
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]

    static baseBuildFields = [
        {
            records: [
                'id',
                'name',
                'description',
                'status',
                'eta',
                {
                    BuildHasImageFile: [
                        {
                            relations: [
                                {
                                    record: [
                                        'id',
                                        'name',
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]

    static shopBuildFields = [
        'recordsCount',
        {
            records: [
                'id',
                'name',
                'description',
                'isArchived',
                'userId',
                'eta',
                'customerBuildSheetUrl',
                'shopBuildSheetUrl',
                'status',
                {
                    BuildIsOwnedByPerson: [
                        {
                            relations: [
                                {
                                    record: [
                                        'id',
                                        'name'
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]

    static shopBuildFieldsDropDown = [
        'recordsCount',
        {
            records: [
                'id',
                'name',
                'userId',
            ]
        }
    ]

    static buildConversationImages = [
        {
            records: [
                {
                    BuildHasConversationImageFile: [
                        'relationsCount',
                        {
                            relations: [
                                {
                                    record: [
                                        'id',
                                        'name',
                                    ]
                                }
                            ]
                        }
                    ]
                },
            ]
        }
    ]

    static allMessageFields = [
        'recordsCount',
        {
            records: [
                'id',
                'textContent',
                'buildId',
                'userId',
                'username',
                'created',
                'tags',
                {
                    MessageHasAttachmentFile: [
                        {
                            relations: [
                                {
                                    record: [
                                        'id',
                                        'name',
                                    ]
                                }
                            ]
                        }
                    ]
                },
            ]
        }
    ]

    static personFields = [
        'recordsCount',
        {
            records: [
                'id',
                'name',
                'firstName',
                'lastName',
                'userId',
                'email',
                'phoneNumber',
                'isArchived',
                'role',
                'receivesEmails',
                'receivesSms',
                {
                    PersonOwnsBuild: [
                        {
                            relations: [
                                {
                                    record: [
                                        'id',
                                        'name',
                                        'isArchived'
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]

    static personForArchiveFields = [
        'recordsCount',
        {
            records: [
                'id',
                {
                    PersonOwnsBuild: [
                        {
                            relations: [
                                {
                                    record: [
                                        'id',
                                        'isArchived'
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]

    static clientFields = [
        'recordsCount',
        {
            records: [
                'id',
                'name',
                'firstName',
                'lastName',
                'userId',
                'email',
                'phoneNumber',
                'receivesEmails',
                'receivesSms',
                {
                    PersonOwnsBuild: [
                        {
                            relations: [
                                {
                                    record: [
                                        'id',
                                        'name',
                                        'isArchived'
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]

    static fileAttachedToMessageFields = [
        'recordsCount',
        {
            records: [
                {
                    FileIsAttachedToMessage: [
                        {
                            relations: [
                                {
                                    record: [
                                        'userId'
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}
