import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {Routes} from "../../../utils/appEnums/AppEnums";
import {useHistory} from "react-router";
import './notFound.css'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative'
        },
        text: {
            color: 'white'
        },
    }),
);

const NotFound = () => {
  const classes = useStyles();
  const themeProps = useSelector((store: any) => store.uiReducer.themeProps);
  const history = useHistory();

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={'app-wrapper-image'} style={{...themeProps.appBackgroundStyle}}/>
      <Container maxWidth={'lg'} style={{paddingTop: 177}} className={'app-container'}>
        <div className={classes.root}>
          <Typography variant="h1" align="center"  className={`${classes.text } text-mobile`}>
            404
           </Typography>
          <Typography variant="h2" align="center" className={`${classes.text } text-mobile-text`}>
            WE ARE SORRY, PAGE NOT FOUND!
          </Typography>
          <Typography align="center" style={{paddingTop: 40}} className={`${classes.text } text-mobile-info`}>
            THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN REMOVED, HAD ITS NAME CHANGED, OR IS TEMPORARILY UNAVAILABLE.
          </Typography>
            <div className={'home-btn-container'}>
                <Button color="secondary" variant="contained" className={'home-btn'}
                        onClick={() => {
                            history.push(Routes.BASE_PATH)
                        }}>
                    Go Back To Home
                </Button>
            </div>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default NotFound;
