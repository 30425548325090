import * as React from 'react'
import AppHeader from "../components/AppHeader/AppHeader";
import Wrapper from "./Wrapper";
import EditProfile from "../components/EditProfile/EditProfile";
import {useDispatch, useSelector} from "react-redux";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {fade, OutlinedInputProps, TextField} from "@material-ui/core";

export const CustomTextField = (props: any) => {
    // const customTextFieldStyles = makeStyles((theme: Theme) =>
    //     createStyles({
    //         root: {
    //             // border: `1px solid ${theme.palette.primary.main}`,
    //             overflow: 'hidden',
    //             borderRadius: 4,
    //             backgroundColor: '#2d2727',
    //             color: theme.palette.primary.contrastText,
    //             // transition: theme.transitions.create(['border-color', 'box-shadow']),
    //             // '&:hover': {
    //             //     backgroundColor: '#2d2727',
    //             // },
    //             // '&$focused': {
    //             //     backgroundColor: '#2d2727',
    //             //     boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    //             //     borderColor: "white",
    //             // },
    //         },
    //         // focused: {},
    //     }),
    // );

    // const classes = customTextFieldStyles();
    return <TextField
        {...props}
        autoComplete='off'
    />;
};

const MainLayout = (props: any) => {
    const isOpen = useSelector((store: any) => store.uiReducer.isProfileModalOpen);
    const currentPerson = useSelector((store: any) => store.personReducer.person);
    const dispatch = useDispatch();

    const handleModalClose = () => {
        dispatch({type: 'isProfileModalOpen', isProfileModalOpen: false});
    }

    return (
        <React.Fragment>
            <Wrapper>
                <AppHeader/>
                {isOpen && currentPerson ?
                    <EditProfile open={isOpen} handleClose={handleModalClose}
                                 clientForEdit={currentPerson}/> : null}
                {props.children}
            </Wrapper>
        </React.Fragment>
    )
}
export default MainLayout
