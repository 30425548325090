export const actions = {
    SET_PERSON: 'SET_PERSON'
};

export interface PersonReducerState {
    person: any;
}

const initialState: PersonReducerState = {
    person: null
}
// Person is a custom object used for client/merchant information
const PersonReducer = (state: PersonReducerState = initialState, action: any) => {
    state = {...state};

    switch (action.type) {
        case actions.SET_PERSON:
            state.person = action.payload;
            return state;
        default:
            return state
    }
}
export default PersonReducer
