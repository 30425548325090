import React from 'react';
import { useState } from "react";
import {useTypedSelector} from "../../../store";
import './actionSpinner.css';
import {PuffLoader} from "react-spinners";
import { useTheme } from "@material-ui/core/styles";

const ActionSpinner = () => {
    const theme = useTheme();
    let [color] = useState(theme.palette.primary.main);
    const show = useTypedSelector((state) => state.spinnerReducer.showSpinner)

    return (
        <div className={show ? 'showActionSpinner' : 'hideActionSpinner'} key={'spinner'}>
            <PuffLoader color={color} loading={show} size={150} />
        </div>
    );
}

export default ActionSpinner
