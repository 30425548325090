import { createStore } from 'redux'
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import baseReducer from "./reducers/baseReducer";

const store = createStore(baseReducer)

export default store

// https://react-redux.js.org/using-react-redux/static-typing#typing-the-useselector-hook
export const useTypedSelector: TypedUseSelectorHook<any> = useSelector
