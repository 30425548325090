import * as React from 'react';
import {createStyles, Theme, makeStyles, withStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {Checkbox} from "@material-ui/core";
import {useHistory} from "react-router";
import EditIcon from "@material-ui/icons/Edit";
import ArchiveIcon from '@material-ui/icons/Archive';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Button from "@material-ui/core/Button";
import AuthUtils from "../../../../utils/AuthUtils";

export const CustomCheckbox = withStyles({
    // root: {
    //     color: '#FF5200',
    //     '&$checked': {
    //         color: '#FF5200',
    //     },
    // },
    checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

const BuildCard = (props: any) => {
    const history = useHistory();
    const useStyles = makeStyles((theme: Theme) => createStyles({
        paper: {
            padding: theme.spacing(3),
            width: '100%',
            // backgroundColor: 'rgba(0, 0, 0, 0.55)',
            // color: '#fff',
            border: `2px solid ${theme.palette.primary.main}`
        },
        buildName: {
            wordBreak: "break-word",
            textDecoration: "none",
            cursor: "pointer",
            '@media (max-width: 500px)': {
                fontSize: '1.7rem'
            }
        },
        buildInfoText: {
            wordBreak: 'break-word',
            '@media (max-width: 500px)': {
                fontSize: '1.05rem'
            }
        },
        archivedLabel: {
            color: '#FF5200',
            wordBreak: "break-word"
        },
        sheetLink: {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        listItemThirdContainer: {
            '@media (max-width: 500px)': {
                padding: '8px 0px 8px 8px !important'
            }
        },
        icon: {
            color: theme.palette.primary.contrastText
        }
    }));

    const classes = useStyles();

    const handleSelectionChange = () => {
        props.onSelect(props.clientBuild.id, !props.clientBuild.isSelected)
    }

    const navigateToProject = (id: number) => {
        history.push({
            pathname: `/project/id=${id}`,
            // @ts-ignore
            state: {id: id}
        });
    }

    const editBuild = () => {
        props.editBuild(props.clientBuild);
    };

    const onLinkClick = (url) => {
        if (url !== '' && url !== 'n/a') {
            window.open(url, '_blank');
        }
    };

    const generateLink = (url, title) => {
        const missingUrl = url === 'n/a' || url === '';
        return <span onClick={() => onLinkClick(url)}
                     className={missingUrl ? '' : classes.sheetLink}>{title}{missingUrl ? ': n/a' : ''}</span>
    };

    return (
        <ListItem style={{paddingRight: 0}}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm container className={classes.listItemThirdContainer}>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "space-between"
                                }}>
                                    <div className={'checkbox-container'}>
                                        <CustomCheckbox gutterBottom checked={props.clientBuild?.isSelected}
                                                        onChange={handleSelectionChange} name="checked"/>
                                        <Typography gutterBottom variant="h4" className={classes.buildName}
                                                    onClick={() => {
                                                        navigateToProject(props.clientBuild.id)
                                                    }}>
                                            {props.clientBuild.buildName}
                                        </Typography>
                                    </div>
                                    { AuthUtils.hasUserEmployeeRole() ? null
                                        : <Button
                                        className={'client-icon-btn'}
                                        aria-label={'Edit Build'}
                                        title={'Edit Build'}
                                        variant="contained"
                                        color="secondary"
                                        size={'small'}
                                        style={{minWidth: 22, height: 40}}
                                        onClick={editBuild}
                                    >
                                        <EditIcon/>
                                    </Button>}
                                </div>
                                <div className={'info-row'}>
                                    <div className={'client-info-container'}>
                                        <div className={'name-icon-container'}>
                                            <AccountBoxIcon className={`client-li-icon ${classes.icon}`}/>
                                            <Typography variant="h6" gutterBottom className={classes.buildInfoText}>
                                                User: {props.clientBuild.clientName}
                                            </Typography>
                                        </div>
                                        {AuthUtils.hasUserEmployeeRole() ? null :
                                            <React.Fragment>
                                                <div className={'client-div-spacer'}/>
                                                <div className={'name-icon-container'}>
                                                    <ListAltIcon className={`client-li-icon ${classes.icon}`}/>
                                                    <Typography variant="h6" gutterBottom
                                                                className={classes.buildInfoText}>
                                                        {generateLink(props.clientBuild.customerBuildSheetUrl, 'Customer Build Sheet')}
                                                    </Typography>
                                                </div>
                                            </React.Fragment>}
                                        <div className={'client-div-spacer'}/>
                                        <div className={'name-icon-container'}>
                                            <ListAltIcon className={`client-li-icon ${classes.icon}`}/>
                                            <Typography variant="h6" gutterBottom className={classes.buildInfoText}>
                                                {generateLink(props.clientBuild.shopBuildSheetUrl, 'Shop Build Sheet')}
                                            </Typography>
                                        </div>
                                    </div>
                                    {props.clientBuild.isArchived ?
                                        <span title={'Archived'} className={'archived-span'}>
                                        <ArchiveIcon style={{padding: 9, marginTop: 5, fontSize: "xx-large"}}/>
                                    </span> : null
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </ListItem>
    )
}

export default BuildCard

