import { combineReducers } from 'redux'
import spinnerReducer, {SpinnerReducerState} from './spinnerReducer'
import userReducer, {UserReducerState} from "./userReducer";
import uiReducer, {UiReducerState} from "./uiReducer";
import personReducer, {PersonReducerState} from "./personReducer";

export interface BaseReducerState {
    spinnerReducer: SpinnerReducerState,
    userReducer: UserReducerState,
    uiReducer: UiReducerState,
    personReducer: PersonReducerState
}

export default combineReducers<BaseReducerState>({
    spinnerReducer: spinnerReducer,
    userReducer: userReducer,
    uiReducer: uiReducer,
    personReducer: personReducer
})
