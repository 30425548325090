import * as React from "react";
import {useToasts} from 'react-toast-notifications';
import {useEffect} from "react";
import AppUtils from "../../utils/AppUtils";

const Toast = (props: any) => {
    const {addToast} = useToasts();

    useEffect(() => {
        AppUtils.toast = addToast;
    }, [])

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}
export default Toast
