import * as React from 'react'
import {FormattedMessage} from "react-intl";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import './errorBoundary.css'

interface State {
    hasError: boolean
}

interface Props {

}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return <div className={'error-boundary-wrapper'}>
                <div className={'error-boundary-container'}>
                    <div>
                       <ReportProblemIcon style={{fontSize: '5.5rem'}}/>
                    </div>
                    <h1 className={'error-boundary-heading'}>
                        <FormattedMessage
                            id="error.page.info-header"
                            defaultMessage="Ooops! Something went wrong."
                        />
                    </h1>
                    <h5 className={'error-boundary-heading'}>
                        <FormattedMessage
                            id="error.page.info-message"
                            defaultMessage="This page didn't load the content correctly. See the JavaScript console for technical details."
                        />
                    </h5>
                </div>
            </div>
        }
        return this.props.children;
    }
}

export default ErrorBoundary
