import store from "../../store";
import {AxiosInstance, AxiosRequestConfig} from "axios";
import Axios from "./Axios";
import AppUtils from "../../utils/AppUtils";

class AxiosActions {
    static runningRequests: number = 0;

    static async post(axiosInstance: AxiosInstance, url: string, data: any, config: AxiosRequestConfig, spinner: boolean,hideErrors?: boolean, errorPopup?: boolean): Promise<any> {
        if (spinner) {
            AxiosActions.runningRequests++;
            store.dispatch({type: 'set', showSpinner: true})
        }
        try {
            const response = await axiosInstance.post(url, data, config);
            AxiosActions.hideSpinner(spinner);
            return response;
        } catch (e) {
            AxiosActions.hideSpinner(spinner)
            if (!hideErrors) {
                AxiosActions.errorHandler(e, errorPopup);
            }
        }
    }

    static async get(axiosInstance: AxiosInstance, url: string, config: AxiosRequestConfig, spinner: boolean, hideErrors?: boolean, errorPopup?: boolean): Promise<any> {
        if (spinner) {
            AxiosActions.runningRequests++;
            store.dispatch({type: 'set', showSpinner: true})
        }
        try {
            const response = await axiosInstance.get(url, config);
            AxiosActions.hideSpinner(spinner);
            return response;
        } catch (e) {
            AxiosActions.hideSpinner(spinner)
            if (!hideErrors) {
                AxiosActions.errorHandler(e, errorPopup);
            }
        }
    }

    static async requestWithProgress(axiosInstance: AxiosInstance, method: string, url: string, data: any,  config: AxiosRequestConfig, spinner: boolean, hideErrors?: boolean, errorPopup?: boolean): Promise<any> {
        if (spinner) {
            AxiosActions.runningRequests++;
            store.dispatch({type: 'set', progressSpinner: {show: true, progress: 0}})
        }
        try {
            let response;
            switch (method){
                case 'get':
                    response = await axiosInstance.get(url, {
                        ...config, onDownloadProgress(progressEvent) {
                            store.dispatch({type: 'set',
                                progressSpinner: {
                                    show: true,
                                    progress: Math.round((progressEvent.loaded / progressEvent.total) * 100)
                                }
                            })
                        }
                    });
                    break;
                case 'post':
                    response = await axiosInstance.post(url, data,{
                        ...config, onUploadProgress(progressEvent) {
                            store.dispatch({type: 'set',
                                progressSpinner: {
                                    show: true,
                                    progress: Math.round((progressEvent.loaded / progressEvent.total) * 100)
                                }
                            })
                        }
                    });
                    break;
            }

            AxiosActions.hideSpinner(spinner, true);
            return response;
        } catch (e) {
            AxiosActions.hideSpinner(spinner, true)
            if (!hideErrors) {
                AxiosActions.errorHandler(e, errorPopup);
            }
        }
    }

    static async put(axiosInstance: AxiosInstance, url: string, data: any, config: AxiosRequestConfig, spinner: boolean, hideErrors?: boolean, errorPopup?: boolean): Promise<any> {
        if (spinner) {
            AxiosActions.runningRequests++;
            store.dispatch({type: 'set', showSpinner: true})
        }
        try {
            const response = await axiosInstance.put(url, data, config);
            AxiosActions.hideSpinner(spinner);
            return response;
        } catch (e) {
            AxiosActions.hideSpinner(spinner)
            if (!hideErrors) {
                AxiosActions.errorHandler(e, errorPopup);
            }
        }
    }

    static async delete(axiosInstance: AxiosInstance, url: string, config: AxiosRequestConfig, spinner: boolean, hideErrors?: boolean, errorPopup?: boolean): Promise<any> {
        if (spinner) {
            AxiosActions.runningRequests++;
            store.dispatch({type: 'set', showSpinner: true})
        }
        try {
            const response = await axiosInstance.delete(url, config);
            AxiosActions.hideSpinner(spinner);
            return response;
        } catch (e) {
            AxiosActions.hideSpinner(spinner)
            if (!hideErrors) {
                AxiosActions.errorHandler(e, errorPopup);
            }
        }
    }

    static async all<T>(values: (T | Promise<T>)[], spinner: boolean, hideErrors?: boolean,  errorPopup?: boolean): Promise<any> {
        if (spinner) {
            AxiosActions.runningRequests++;
            store.dispatch({type: 'set', showSpinner: true})
        }
        try {
            const response = await Axios.all(values);
            AxiosActions.hideSpinner(spinner);
            return response
        } catch (e) {
            AxiosActions.hideSpinner(spinner)
            if (!hideErrors) {
                AxiosActions.errorHandler(e, errorPopup);
            }
        }
    };

    static errorHandler = (error: any, errorPopup?: boolean) => {
        AxiosActions.showError(error, errorPopup);
    }

    static hideSpinner = (spinner: boolean, withProgress?: boolean) => {
        if (spinner) {
            AxiosActions.runningRequests--;
            if (AxiosActions.runningRequests === 0) {
                if (withProgress) {
                    setTimeout(() => {
                        store.dispatch({type: 'set', progressSpinner: {show: false, progress: 0}})
                    }, 1000)
                } else {
                    store.dispatch({type: 'set', showSpinner: false})
                }
            }
        }
    }

    static showError = (error: any, errorPopup?: boolean) => {
        if (error.response?.data?.message) {
            if (errorPopup) {
                // for popup error handlers
            } else {
                AppUtils.error(error.response.data.message)
            }
        } else if (error.response?.data?.error_description) {
            if (errorPopup) {
                // for popup error handlers
            } else {
                AppUtils.error(error.response.data.error_description)
            }
        } else {
            if (errorPopup) {
               // for popup error handlers
            } else {
                AppUtils.error('Something went wrong')
            }
        }
    }

}

export default AxiosActions;
