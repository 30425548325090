import {PublicRoutes} from './appEnums/AppEnums'
import {LOCALE_DETAILS} from "../config/LocaleDetails";
import {AddToast, Options} from "react-toast-notifications";

const axios = require('axios');
const moment = require("moment");
const moment_timezone = require("moment-timezone/builds/moment-timezone-with-data-1970-2030");

export default class AppUtils {
    static readonly axios = axios;
    // static readonly store = store;
    static readonly moment = moment;
    static readonly moment_timezone = moment_timezone;
    static readonly timezone = AppUtils.getCookie("Time-Zone") || AppUtils.setCookie("Time-Zone", AppUtils.moment_timezone.tz.guess(), 1000);

    /* Regular Expressions */
    static readonly VALID_EMAIL_REGEX = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    static readonly EMAIL_REGEX = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g);
    static readonly ALL_EMAIL_DOMAIN_REGEX = new RegExp(/^[*]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g);

    /* Application */
    static readonly APP_DEFAULT_LANGUAGE = "en";
    static readonly API_BASE_URL_V1_POSTFIX = "/v1";
    static readonly API_BASE_URL_V2_POSTFIX = "/v2";
    static readonly LOCAL_STORAGE_ITEM_LOCALE = "Locale";
    static readonly LOCAL_STORAGE_ITEM_LANG = "Language";

    static readonly SUPPORTED_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'];
    static readonly FUTURE_TIME = '3000-01-01 00:00:00';


    /* Toast*/
    static toast: AddToast;

    static customMessage(message: string, options: Options) {
        if (AppUtils.toast) {
            AppUtils.toast(message, options)
        }
    }

    static error(message: string) {
        if (AppUtils.toast) {
            AppUtils.toast(message, {appearance: 'error', autoDismiss: true, autoDismissTimeout: 5000})
        }
    }

    static success(message: string) {
        if (AppUtils.toast) {
            AppUtils.toast(message, {appearance: 'success', autoDismiss: true, autoDismissTimeout: 5000})
        }
    }

    static warning(message: string) {
        if (AppUtils.toast) {
            AppUtils.toast(message, {appearance: 'warning', autoDismiss: true, autoDismissTimeout: 5000})
        }
    }

    static info(message: string) {
        if (AppUtils.toast) {
            AppUtils.toast(message, {appearance: 'info', autoDismiss: true, autoDismissTimeout: 5000})
        }
    }

    /* Toast*/


    static isPathPublic(pathname: string) {
        const routes = Object.keys(PublicRoutes);
        const publicRoutes: string[] =  routes.map(route => {
            return `${PublicRoutes[route]}`
        });

        for (let publicPath of publicRoutes) {
            if (publicPath === pathname) {
                return true;
            }
        }
        return false;
    }

    static setCookie(cname: string, cvalue: any, exdays: number): string {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        return cvalue
    }

    static getCookie(cname: string): string {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static getLocaleNameByTag = (localeTag: string) => {
        const label = LOCALE_DETAILS[localeTag];
        return label ? label : localeTag;
    };

    static localToUtc = (date: string, inFormat: string, outFormat: string): string => {
        const moment = moment_timezone.tz(date, inFormat, AppUtils.timezone);
        return moment.utc().format(outFormat);
    };

    static utcToLocalDateTime = (dateString: string, hour: boolean): string => {
        // get instance dates missing 'Z' at the end
        if (dateString.charAt(dateString.length-1) !== 'Z') {
            dateString = dateString + 'Z';
        }
        if (hour) {
            return AppUtils.formatDateTime(AppUtils.moment_timezone.tz(dateString, AppUtils.timezone));
        }
        return AppUtils.formatDate(AppUtils.moment_timezone.tz(dateString, AppUtils.timezone));
    };

    static utcToLocalTime = (dateString: string): string => {
        // get instance dates missing 'Z' at the end
        if (dateString.charAt(dateString.length-1) !== 'Z') {
            dateString = dateString + 'Z';
        }
        return AppUtils.formatTime(AppUtils.moment_timezone.tz(dateString, AppUtils.timezone));
    };

    static formatDateTime = (date: any): string => {
        return date.format("L LT");
    };

    static formatDate = (date: any): string => {
        return date.format("L");
    };

    static formatTime = (date: any): string => {
        return date.format("LT");
    };

    static getUploadedImagesAsArray = (event): any[] => {
        const maxFileSize = 6291456; // 6mb
        const images: any = [];
        const allFiles = event.target ? event.target.files : event;
        const keys = Object.keys(allFiles);
        let unsupportedFiles = false;
        keys.forEach(fileKey => {
            if (AppUtils.SUPPORTED_FILE_TYPES.includes(allFiles[fileKey].type) && allFiles[fileKey].size <= maxFileSize) {
                images.push(allFiles[fileKey]);
            } else {
                unsupportedFiles = true;
            }
        })
        if (unsupportedFiles) {
            AppUtils.warning('Please upload only images. Unsupported files and images larger than 6mb will be skipped.')
        }
        return images;
    }
}
