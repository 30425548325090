const jquery = require('jquery');

/**
 * Retrieve string of message by key from lang file
 *
 * @param id - key of message
 * @param defaultMessage
 * @param intlProps
 * @returns String - Message value
 */
export function getMessageString(id:any, defaultMessage:any, intlProps:any) {
    const { formatMessage } = intlProps;
    return formatMessage({ id: id, defaultMessage: defaultMessage})
};

export function getMessageStringWithParams(id:any, defaultMessage:any, intlProps:any, messageParamValueObject: any) {
    const { formatMessage } = intlProps;
    return formatMessage({ id: id, defaultMessage: defaultMessage}, messageParamValueObject)
}

export default class IntlUtils {
    public static readonly $ = jquery;
};